import { useState, useEffect, useRef } from "react";
import { AppTopbar } from "../app/features/components/app_top_bar";
import { AppConfig } from "../AppConfig";
import { Outlet, useLocation } from "react-router-dom";
import classNames from "classnames";
import { Tooltip } from "primereact/tooltip";
import PrimeReact from "primereact/api";
import "primereact/resources/primereact.css";
import { AppMenu } from "../app/features/components/app_menu";
import { CSSTransition } from "react-transition-group";
import dashIcon from "../assets/assests/dashboard.svg";
import empIcon from "../assets/assests/employee.svg";
import attendanceIcon from "../assets/assests/Attendance.svg";
import leave from "../assets/assests/leave.svg";
import shiftIcon from "../assets/assests/Shift.svg";
import settingIcon from "../assets/assests/Settings.svg";

const DashboardLayout = ({ isLoggedIn, handleLogout, ...topbarProps }) => {
  const copyTooltipRef = useRef();
  const location = useLocation();
  const [layoutMode, setLayoutMode] = useState("static");
  const [layoutColorMode, setLayoutColorMode] = useState("light");
  const [inputStyle, setInputStyle] = useState("outlined");
  const [ripple, setRipple] = useState(true);
  const [staticMenuInactive, setStaticMenuInactive] = useState(false);
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
  const [currentRouteLabel, setCurrentRouteLabel] = useState("");

  PrimeReact.ripple = true;

  let menuClick = false;
  let mobileTopbarMenuClick = false;

  useEffect(() => {
    if (mobileMenuActive) {
      addClass(document.body, "body-overflow-hidden");
    } else {
      removeClass(document.body, "body-overflow-hidden");
    }
  }, [mobileMenuActive]);

  useEffect(() => {
    copyTooltipRef &&
      copyTooltipRef.current &&
      copyTooltipRef.current.updateTargetEvents();
  }, [location]);

  const onInputStyleChange = (inputStyle) => {
    setInputStyle(inputStyle);
  };

  const onRipple = (e) => {
    PrimeReact.ripple = e.value;
    setRipple(e.value);
  };

  const onLayoutModeChange = (mode) => {
    setLayoutMode(mode);
  };

  const onColorModeChange = (mode) => {
    setLayoutColorMode(mode);
  };

  const onWrapperClick = (event) => {
    if (!menuClick) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }

    if (!mobileTopbarMenuClick) {
      setMobileTopbarMenuActive(false);
    }

    mobileTopbarMenuClick = false;
    menuClick = false;
  };

  const onToggleMenuClick = (event) => {
    menuClick = true;

    if (isDesktop()) {
      if (layoutMode === "overlay") {
        if (mobileMenuActive === true) {
          setOverlayMenuActive(true);
        }

        setOverlayMenuActive((prevState) => !prevState);
        setMobileMenuActive(false);
      } else if (layoutMode === "static") {
        setStaticMenuInactive((prevState) => !prevState);
      }
    } else {
      setMobileMenuActive((prevState) => !prevState);
    }

    event.preventDefault();
  };

  const onSidebarClick = () => {
    menuClick = true;
  };

  const onMobileTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true;

    setMobileTopbarMenuActive((prevState) => !prevState);
    event.preventDefault();
  };

  const onMobileSubTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true;

    event.preventDefault();
  };

  const isDesktop = () => {
    return window.innerWidth >= 992;
  };

  const addClass = (element, className) => {
    if (element.classList) element.classList.add(className);
    else element.className += " " + className;
  };

  const removeClass = (element, className) => {
    if (element.classList) element.classList.remove(className);
    else
      element.className = element.className.replace(
        new RegExp(
          "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
  };

  const wrapperClass = classNames("layout-wrapper", {
    "layout-overlay": layoutMode === "overlay",
    "layout-static": layoutMode === "static",
    "layout-static-sidebar-inactive":
      staticMenuInactive && layoutMode === "static",
    "layout-overlay-sidebar-active":
      overlayMenuActive && layoutMode === "overlay",
    "layout-mobile-sidebar-active": mobileMenuActive,
    "p-input-filled": inputStyle === "filled",
    "p-ripple-disabled": ripple === false,
    "layout-theme-light": layoutColorMode === "light",
  });

  const findCurrentRouteLabel = (location) => {
    const path = location.pathname;

    // Helper function to recursively search through menu items for exact or parent matches
    const findLabel = (menuItems, parentLabel = "") => {
      for (const item of menuItems) {
        // Check for an exact match
        if (item.to === path) {
          return parentLabel ? (
            <>
              {parentLabel}&nbsp; <i className="pi pi-arrow-right"></i> &nbsp;
              {item.label}
            </>
          ) : (
            item.label
          );
        }

        // Check for a parent match
        if (
          item.to !== path &&
          path.startsWith(item.to) &&
          item.to !== "/" &&
          !path.includes(":")
        ) {
          return parentLabel ? (
            <>
              {parentLabel}&nbsp; <i className="pi pi-arrow-right"></i>&nbsp;{" "}
              {item.label}
            </>
          ) : (
            item.label
          );
        }

        // If there are nested items, check recursively
        if (item.items) {
          const nestedLabel = findLabel(item.items, item.label);
          if (nestedLabel) {
            return parentLabel ? (
              <>
                {parentLabel}&nbsp; <i className="pi pi-arrow-right"></i>&nbsp;
                {nestedLabel}
              </>
            ) : (
              nestedLabel
            );
          }
        }
      }
      return null;
    };

    // Find the label by traversing the menu structure
    const matchedLabel = findLabel(menu);

    // Return matched label or default to "Unknown"
    return matchedLabel || "Unknown";
  };

  // Usage in useEffect
  useEffect(() => {
    setCurrentRouteLabel(findCurrentRouteLabel(location));
  }, [location]);

  const menu = [
    {
      label: "Dashboard",
      to: "/",
      icon: shiftIcon,
    },
    {
      label: "Employee",
      to: "/employee",
      icon: empIcon,
    },
    {
      label: "Attendance",
      to: "/attendance",
      icon: attendanceIcon,
    },
    {
      label: "Leave",
      to: "/leave",
      icon: leave,
    },
    {
      label: "Shift",
      to: "/shift",
      icon: shiftIcon,
    },

    {
      label: "Settings",
      // to: "/settings",
      icon: settingIcon,
      className: "settings-item",
      items: [
        { label: "Leave Type", to: "/settings/leaveType" },
        { label: "Department", to: "/settings/department" },
        { label: "Designation", to: "/settings/designation" },
      ],
    },
  ];

  const filteredMenu = menu.filter((item) => item.visible !== false);

  return (
    <>
      <div className={wrapperClass} onClick={onWrapperClick}>
        <Tooltip
          ref={copyTooltipRef}
          target=".block-action-copy"
          position="bottom"
          content="Copied to clipboard"
          event="focus"
        />

        <div className="layout-sidebar" onClick={onSidebarClick}>
          <AppMenu model={filteredMenu} layoutColorMode={layoutColorMode} />
        </div>

        <div className="layout-main-container">
          <AppTopbar
            isLoggedIn={isLoggedIn}
            handleLogout={handleLogout}
            onToggleMenuClick={onToggleMenuClick}
            layoutColorMode={layoutColorMode}
            mobileTopbarMenuActive={mobileTopbarMenuActive}
            onMobileTopbarMenuClick={onMobileTopbarMenuClick}
            onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}
            currentRouteLabel={currentRouteLabel}
          />
          <div className="layout-main">
            <Outlet />
          </div>
        </div>
      </div>
      <AppConfig
        rippleEffect={ripple}
        onRippleEffect={onRipple}
        inputStyle={inputStyle}
        onInputStyleChange={onInputStyleChange}
        layoutMode={layoutMode}
        onLayoutModeChange={onLayoutModeChange}
        layoutColorMode={layoutColorMode}
        onColorModeChange={onColorModeChange}
      />

      <CSSTransition
        classNames="layout-mask"
        timeout={{ enter: 200, exit: 200 }}
        in={mobileMenuActive}
        unmountOnExit
      >
        <div className="layout-mask p-component-overlay"></div>
      </CSSTransition>
    </>
  );
};

export default DashboardLayout;
